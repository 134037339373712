
<div :id="`user-group-select-${groupName}`" class="user-input-rde">
  <h6>{{ groupName }}</h6>
  <RadioGroup
    v-model="multiSelectType"
    :items="[
      {
        label: 'User',
        value: 'user',
      },
      {
        label: 'Group User',
        value: 'group',
      },
    ]"
    @input="handleTypeChange"
  />
  <v-autocomplete
    v-if="multiSelectType === 'user'"
    :id="`user-group-select-dropdown-${groupName}`"
    v-model="values[mapFields]"
    :items="final_user_items"
    outlined
    dense
    small-chips
    multiple
    clearable
    deletable-chips
    class="app-g-autocomplete-v2"
    label="User"
    :rules="[validateRequired]"
  />

  <v-autocomplete
    v-if="multiSelectType === 'group'"
    v-model="selectedUserGroups"
    :items="user_group_items"
    itemText="name"
    itemValue="members"
    outlined
    dense
    small-chips
    multiple
    clearable
    deletable-chips
    class="app-g-autocomplete-v2"
    label="Group User"
    :rules="[validateRequired]"
    @change="handleChangeGroup"
  />
</div>
