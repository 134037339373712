import { Client } from "@stomp/stompjs";
import SockJS from "sockjs-client/dist/sockjs";

const { VUE_APP_NOTIFICATION_SOCKET_URL } = process.env;

export const connectWebSocket = (options) => {
  const { token } = options || {};

  return new Promise((resolve, reject) => {
    const socket = new Client({
      webSocketFactory: () => new SockJS(VUE_APP_NOTIFICATION_SOCKET_URL),
      debug: console.log,
      connectHeaders: { token },
      reconnectDelay: 5000,
      onConnect: () => resolve(socket),
      onStompError: reject,
      onWebSocketClose: reject,
      onWebSocketError: reject,
    });

    socket.activate();
  });
};
